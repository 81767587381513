import React from "react";
import {
  Typography,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function Onboarding() {
  const { control } = useFormContext();
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="contractExperienceDeadlineOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="contract-experience-deadline-onboarding-label">
                    Prazo de cumprimento do contrato de experiência:
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="contract-experience-deadline-onboarding-label"
                    id="contract-experience-deadline-onboarding"
                    variant="outlined"
                    label="Prazo de cumprimento do contrato de experiência:"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasEmployeeRegistrationRuleOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existe regra para o cadastro de matrículas dos colaboradores?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="tracksWorkingHoursOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="tracks-working-hours-onboarding-label">
                    Realiza controle de jornada?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="tracks-working-hours-onboarding-label"
                    id="tracks-working-hours-onboarding"
                    variant="outlined"
                    label="Realiza controle de jornada?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="timeTrackingClosurePeriodOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Período de fechamento do ponto"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        {/* </Box> */}
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Adiantamento
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasMonthlyAdvancePaymentOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Possui adiantamento mensal?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="monthlyAdmittedEmployeesOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="monthly-admitted-employees-onboarding-label">
                    Admitidos no mês?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="monthly-admitted-employees-onboarding-label"
                    id="monthly-admitted-employees-onboarding"
                    variant="outlined"
                    label="Admitidos no mês?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="maternityLeaveOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="maternity-leave-onboarding-label">
                    Licença maternidade?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="maternity-leave-onboarding-label"
                    id="maternity-onboarding-leave"
                    variant="outlined"
                    label="Licença maternidade?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="advancePaymentConditionsOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Condições para receber o adiantamento"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="monthlyWorkedDaysOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Quantos dias trabalhados no mês?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Provisão de Férias
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="registersFirstInstallmentAnticipationOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="registers-first-installment-anticipation-onboarding-label">
                    Realiza baixa de antecipação da 1ª parcela?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="registers-first-installment-anticipation-onboarding-label"
                    id="registers-first-installment-anticipation-onboarding"
                    variant="outlined"
                    label="Realiza baixa de antecipação da 1ª parcela?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="analyticalReportOrderDescriptionOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Em caso de matriz e filial, descrever ordem"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="analyticalReportOrderOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="analytical-report-order-onboarding-label">
                    Relatório analítico por ordem
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="analytical-report-order-onboarding-label"
                    id="analytical-report-order-onboarding"
                    variant="outlined"
                    label="Relatório analítico por ordem"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="syntheticReportOrderDescriptionOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Em caso de matriz e filial, descrever ordem"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="syntheticReportOrderOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="analytical-report-order-onboarding-label">
                    Relatório sintético por ordem
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="analytical-report-order-onboarding-label"
                    id="analytical-report-order-onboarding"
                    variant="outlined"
                    label="Relatório sintético por ordem"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          13° salário
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="firstInstallmentThirteenthWithAveragesOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="first-installment-thirteenth-with-averages-onboarding-label">
                    Pagamento da 1ª parcela do 13° salário com médias?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="first-installment-thirteenth-with-averages-onboarding-label"
                    id="first-installment-thirteenth-with-averages-onboarding"
                    variant="outlined"
                    label="Pagamento da 1ª parcela do 13° salário com médias?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="firstInstallmentAverageValueOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="first-installment-average-value-onboarding-label">
                    Média da 1ª parcela
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="first-installment-average-value-onboarding-label"
                    id="first-installment-average-value-onboarding"
                    variant="outlined"
                    label="Média da 1ª parcela"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="firstInstallmentAnticipationRuleOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existe regra de negócio de antecipação da 1ª parcela?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="firstInstallmentAnticipationPercentageOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Percentual pago na antecipação da 1ª parcela"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="internsBoardChristmasBonusOnboarding"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Estagiário e pró-labore recebem 'gratificação natalina' na Folha de Pagamento de Nov e Dez? Se sim, descreva sob qual verba/rubrica."
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="analyticalReportByOrderOfOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="analytical-report-by-order-of-onboarding-label">
                    Relatório analítico por ordem
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="analytical-report-by-order-of-onboarding-label"
                    id="analytical-report-by-order-of-onboarding"
                    variant="outlined"
                    label="Relatório analítico por ordem de"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="syntheticReportByOrderOfOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="synthetic-report-by-order-of-onboarding-label">
                    Relatório sintético por ordem
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="synthetic-report-by-order-of-onboarding-label"
                    id="synthetic-report-by-order-of-onboarding"
                    variant="outlined"
                    label="Relatório sintético por ordem"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          eSocial
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="eSocialClassificationOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="e-social-framing-label-label">
                    Enquadramento eSocial
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="e-social-classification-onboarding-label"
                    id="e-social-classification-onboarding"
                    variant="outlined"
                    label="Enquadramento eSocial"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Empréstimo Consignado
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="offersPayrollLoanConcessionOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="offers-payroll-loan-concession-onboarding-label">
                    Possui concessão de empréstimo consignado?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="offers-payroll-loan-concession-onboarding-label"
                    id="offers-payroll-loan-concession-onboarding"
                    variant="outlined"
                    label="Possui concessão de empréstimo consignado?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductionLegislationComplianceOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deduction-legislation-compliance-onboarding-label">
                    O desconto será conforme legislação vigente?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deduction-legislation-compliance-onboarding-label"
                    id="deduction-legislation-compliance-onboarding"
                    variant="outlined"
                    label="O desconto será conforme legislação vigente?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="informationReceivingFormatOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="information-receiving-format-onboarding-label">
                    Em que formato recebe a informação?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="information-receiving-format-onboarding-label"
                    id="information-receiving-format-onboarding"
                    variant="outlined"
                    label="Em que formato recebe a informação?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductTotalInstallmentAllowsNegativeBalance"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deduct-total-installment-allows-negative-balance-label">
                    Desconta o valor total da parcela, podendo gerar saldo
                    negativo?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deduct-total-installment-allows-negative-balance-label"
                    id="deduct-total-installment-allows-negative-balance"
                    variant="outlined"
                    label="Desconta o valor total da parcela, podendo gerar saldo negativo?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="allowNegativeBalanceDeductionOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="allow-negative-balance-deduction-onboarding-label">
                    Em que formato recebe a informação?
                  </InputLabel>
                  <Select
                    required
                    {...field}
                    labelId="allow-negative-balance-deduction-onboarding-label"
                    id="allow-negative-balance-deduction-onboarding"
                    variant="outlined"
                    label="Em que formato recebe a informação?"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Em qual data recebem as informações?"
                  fullWidth
                  name="informationReceivedDateOnboarding"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="informationReceivedDateOnboarding"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Empréstimo Cidadã
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="citizenLoanOnboarding"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="citizen-loan-onboarding-label">
                    A empresa é conveniada com o programa 'Empresa Cidadã' -
                    prorrogação da licença-maternidade por mais 60 dias?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="citizen-loan-onboarding-label"
                    id="citizen-loan-onboarding"
                    variant="outlined"
                    label="A empresa é conveniada com o programa 'Empresa Cidadã' - prorrogação da licença-maternidade por mais 60 dias?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
