import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { string, object, boolean } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import { storeAmount, updateAmount } from "../../../../../services/pwsApi";

const amountSchema = object().shape({
  amountName: string().required("Informe o nome da função."),
  hasConfigContabil: boolean().required("Informe o CBO da função."),
});

export function AmountsDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code") || "";
  const company = companyCode.slice(0, 3);
  const subsidiary = companyCode.slice(3, 7);

  const [amountTypeOptions, setAmountTypeOptions] = useState([]);
  const [amountInputTypeOptions, setAmountInputTypeOptions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);

  useEffect(() => {
    setAmountTypeOptions([
      { label: "Provento", value: 1 },
      { label: "Desconto", value: 2 },
      { label: "Base provento", value: 3 },
      { label: "Base desconto", value: 4 },
    ]);

    setAmountInputTypeOptions([
      { label: "Horas", value: 1 },
      { label: "Valor (R$)", value: 2 },
      { label: "Dias", value: 3 },
    ]);

    setDefaultOptions([
      { label: "Sim", value: 1 },
      { label: "Não", value: 2 },
    ]);
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(amountSchema),
    defaultValues: {
      amountId: data?.amountId ?? null,
      hasConfigContabil: data?.hasConfigContabil ?? "",
    },
  });

  const storeMutation = useMutation(storeAmount, {
    onSuccess: () => {
      toast.success("Verba criada com sucesso.");
      queryClient.invalidateQueries("amounts");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao criar verba.");
    },
  });

  const updateMutation = useMutation(
    variables => updateAmount(variables.amountId, variables),
    {
      onSuccess: () => {
        toast.success("Verba atualizada com sucesso.");
        queryClient.invalidateQueries("amounts");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao atualizar verba.");
      },
    },
  );

  const onSubmit = formData => {
    const { amountId } = formData;

    const payload = {
      companyCode: company,
      subsidiaryCode: subsidiary,
    };

    if (amountId) {
      updateMutation.mutate({ amountId, ...payload });
    } else {
      storeMutation.mutate(payload);
    }
  };

  const hasConfigContabil = watch("hasConfigContabil");

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>
        {!data?.amountId ? "Adicionar Verba" : "Editar Verba"}
      </DialogTitle>

      <DialogContent dividers>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="amountName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome da Verba"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.amountName}
                  helperText={errors.amountName?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="amountType"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.amountType}
                >
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    {...field}
                    label="Tipo"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {amountTypeOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.amountType && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.amountType.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="amountInputType"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.amountInputType}
                >
                  <InputLabel>Tipo de lançamento</InputLabel>
                  <Select
                    {...field}
                    label="Tipo de lançamento"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {amountInputTypeOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.amountInputType && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.amountInputType.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="hasFgts"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.hasFgts}
                >
                  <InputLabel>Incide INSS ?</InputLabel>
                  <Select
                    {...field}
                    label="Incide FGTS ?"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {defaultOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.hasFgts && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.hasFgts.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="hasInss"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.hasInss}
                >
                  <InputLabel>Incide INSS ?</InputLabel>
                  <Select
                    {...field}
                    label="Incide INSS ?"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {defaultOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.hasInss && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.hasInss.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="hasIr"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.hasIr}
                >
                  <InputLabel>Incide IR ?</InputLabel>
                  <Select
                    {...field}
                    label="Incide Ir ?"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {defaultOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.hasIr && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.hasIr.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="incorporatesSalary"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.incorporatesSalary}
                >
                  <InputLabel>Incorpora salário?</InputLabel>
                  <Select
                    {...field}
                    label="Incorpora salário ?"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {defaultOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.incorporatesSalary && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.incorporatesSalary.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="media"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.media}
                >
                  <InputLabel>Média ?</InputLabel>
                  <Select
                    {...field}
                    label="Média ?"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {defaultOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.media && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.media.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="hasConfigContabil"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.hasConfigContabil}
                >
                  <InputLabel>Possui configuração contábil ?</InputLabel>
                  <Select
                    {...field}
                    label="Possui configuração contábil ?"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {defaultOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.hasConfigContabil && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.hasConfigContabil.message}
              </p>
            )}
          </div>
        </div>
        {hasConfigContabil === 1 && (
          <div className="row">
            <div className="col-sm-12">
              <Controller
                name="configContabil"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Configuração contábil"
                    margin="normal"
                    fullWidth
                    variant="standard"
                    error={!!errors.configContabil}
                    helperText={errors.configContabil?.message}
                  />
                )}
              />
            </div>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.amountId ? "Adicionar" : "Editar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
