import * as yup from "yup";

export const onboardingSchema = yup.object().shape({
  // Campos do tipo 'select'
  contractDuration: yup
    .string()
    .required("Seleção do prazo de contrato é obrigatória"),
  hasWorkShiftControl: yup
    .string()
    .required("Seleção de controle de jornada é obrigatória"),
  employeesAdmittedInMonth: yup
    .string()
    .required("Seleção de admitidos no mês é obrigatória"),
  hasMaternityLeave: yup
    .string()
    .required("Seleção de licença maternidade é obrigatória"),
  processFirstInstallmentAdvance: yup
    .string()
    .required("Seleção sobre 1ª parcela é obrigatória"),
  analyticalReportByOrder: yup
    .string()
    .required("Seleção de relatório analítico é obrigatória"),
  syntheticReportByOrder: yup
    .string()
    .required("Seleção de relatório sintético é obrigatória"),
  payFirstInstallmentWithAverages: yup
    .string()
    .required("Seleção sobre pagamento da 1ª parcela com médias é obrigatória"),
  firstInstallmentAverage: yup
    .string()
    .required("Seleção sobre média da 1ª parcela é obrigatória"),
  eSocialFraming: yup
    .string()
    .required("Seleção de enquadramento eSocial é obrigatória"),
  hasPayrollLoanConcession: yup
    .string()
    .required("Seleção sobre concessão de empréstimo consignado é obrigatória"),
  deductionFollowsCurrentLegislation: yup
    .string()
    .required("Seleção sobre dedução conforme legislação é obrigatória"),
  informationReceivedFormat: yup
    .string()
    .required("Seleção do formato da informação é obrigatória"),
  deductTotalInstallmentAllowsNegativeBalance: yup
    .string()
    .required("Seleção sobre saldo negativo é obrigatória"),
  companyCitizenProgramMaternityExtension: yup
    .string()
    .required("Seleção sobre Empresa Cidadã é obrigatória"),

  // Campos do tipo 'textfield'
  hasEmployeeRegistrationRule: yup
    .string()
    .required("Regra de matrícula dos colaboradores é obrigatória"),
  timeClockClosingPeriod: yup
    .string()
    .required("Período de fechamento do ponto é obrigatório"),
  hasMonthlyAdvance: yup.string().required("Adiantamento mensal é obrigatório"),
  advancePaymentConditions: yup
    .string()
    .required("Condições para adiantamento são obrigatórias"),
  workingDaysInMonth: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido")
    .required("Número de dias trabalhados no mês é obrigatório"),
  parentSubsidiaryOrderDescription: yup
    .string()
    .required("Descrição de ordem matriz/filial é obrigatória"),
  hasAdvanceBusinessRule: yup
    .string()
    .required("Regra de negócio para antecipação é obrigatória"),
  firstInstallmentAdvancePercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido")
    .required("Percentual de adiantamento é obrigatório"),
  internsProLaboreChristmasBonus: yup
    .string()
    .required("Informação sobre gratificação natalina é obrigatória"),

  // Campos do tipo 'textfield (date)'
  informationReceivedDate: yup
    .date()
    .typeError("Deve ser uma data válida")
    .required("A data de recebimento da informação é obrigatória"),
});
