import * as yup from "yup";

export const payrollSchema = yup.object().shape({
  // Estagiários
  adjustmentReasonForIntern: yup
    .string()
    .required("Adjustment reason for intern is required"),
  appliesDeductionsForIntern: yup
    .string()
    .required("Deductions for intern is required"),
  proportionalDivisionDaysForIntern: yup
    .string()
    .required("Proportional division days for intern is required"),
  paysFixedBenefitForIntern: yup
    .string()
    .required("Fixed benefit for intern is required"),
  fixedBenefitValueForIntern: yup
    .number()
    .required("Fixed benefit value for intern is required")
    .positive("Fixed benefit value must be positive"),

  // Aprendizes
  adjustmentReasonForApprentice: yup
    .string()
    .required("Adjustment reason for apprentice is required"),
  appliesDeductionsForApprentice: yup
    .string()
    .required("Deductions for apprentice is required"),
  benefitDetailsForApprentice: yup
    .string()
    .required("Benefit details for apprentice are required"),
  paysFixedBenefitForApprentice: yup
    .string()
    .required("Fixed benefit for apprentice is required"),
  proportionalDivisionDaysForApprentice: yup
    .number()
    .required("Proportional division days for apprentice is required")
    .positive("Proportional division days must be positive"),
  fixedBenefitValueForApprentice: yup
    .number()
    .required("Fixed benefit value for apprentice is required")
    .positive("Fixed benefit value must be positive"),

  // Autônomos e Pró-labore
  selfEmployedCategory: yup
    .string()
    .required("Self-employed category is required"),
  proLaboreCategory: yup.string().required("Pro-labore category is required"),
  adjustmentReasonForSelfEmployed: yup
    .string()
    .required("Adjustment reason for self-employed is required"),
  withFGTSWithoutEmploymentLink: yup
    .string()
    .required("FGTS without employment link is required"),

  // Relatórios
  analyticalReportOrder: yup
    .string()
    .required("Analytical report order is required"),
  syntheticReportOrder: yup
    .string()
    .required("Synthetic report order is required"),
  specificBusinessFunds: yup
    .string()
    .required("Specific business funds are required"),

  // Seguro de Vida
  lifeInsuranceCategory: yup
    .string()
    .required("Life insurance category is required"),
  movementExecuted: yup.string().required("Movement executed is required"),
  movementReportSubmissionDeadline: yup
    .date()
    .required("Movement report submission deadline is required")
    .typeError("Invalid date format"),
  discountValueForInsurance: yup
    .string()
    .required("Discount value for insurance is required"),
  businessRuleDescriptionForInsurance: yup
    .string()
    .required("Business rule description for insurance is required"),

  // Custos
  companyCost: yup
    .number()
    .required("Company cost is required")
    .positive("Company cost must be positive"),
  employeeCost: yup
    .number()
    .required("Employee cost is required")
    .positive("Employee cost must be positive"),
});
