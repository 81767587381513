import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import WorkshiftHourMask from "./WorkShiftHourMask";
import {
  storeWorkShift,
  updateWorkShift,
} from "../../../../../services/pwsApi";

const workShiftSchema = object().shape({
  workShiftDescription: string().required("Informe a descrição do turno."),
  workShiftType: string().required("Selecione o tipo de turno."),
  normalHours: string().required("Informe as horas normais."),
  workBreak: string().required("Informe o intervalo."),
  previousHours: string().required("Informe as horas antes."),
  laterHours: string().required("Informe as horas depois."),

  mondayType: string().required("Selecione o tipo de dia (segunda)."),
  monday1In: string().required("Informe 1ª entrada (segunda)."),
  monday1Out: string().required("Informe 1ª saída (segunda)."),
  monday2In: string().required("Informe 2ª entrada (segunda)."),
  monday2Out: string().required("Informe 2ª saída (segunda)."),
  monday3In: string().required("Informe 3ª entrada (segunda)."),
  monday3Out: string().required("Informe 3ª saída (segunda)."),
  monday4In: string().required("Informe 4ª entrada (segunda)."),
  monday4Out: string().required("Informe 4ª saída (segunda)."),
  // ... repita para tuesday, wednesday, etc...
  tuesdayType: string().required("Selecione o tipo de dia (terça)."),
  tuesday1In: string().required("Informe 1ª entrada (terça)."),
  // etc...
});

export function WorkShiftsDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code") ?? "";
  const company = companyCode.slice(0, 3);
  const subsidiary = companyCode.slice(3, 7);

  const [workShiftTypeOptions, setWorkShiftTypeOptions] = useState([]);
  useEffect(() => {
    setWorkShiftTypeOptions([
      { label: "44 Hrs Semanal", value: "1" },
      { label: "36 Hrs Semanal", value: "2" },
      { label: "33 Hrs Semanal", value: "3" },
      { label: "30 Hrs Semanal", value: "4" },
      { label: "25 Hrs Semanal", value: "8" },
      { label: "22 Hrs Semanal", value: "5" },
      { label: "20 Hrs Semanal", value: "6" },
      { label: "12h x 36h", value: "7" },
    ]);
  }, []);

  const [typeOfDayOptions, setTypeOfDayOptions] = useState([]);
  useEffect(() => {
    setTypeOfDayOptions([
      { label: "Trabalhado", value: "1" },
      { label: "Compensado", value: "2" },
      { label: "D.S.R", value: "3" },
    ]);
  }, []);

  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const daysDescription = [
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
    "Domingo",
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(workShiftSchema),
    defaultValues: {
      companyCode: company,
      subsidiaryCode: subsidiary,

      id: data?.id ?? null,

      workShiftDescription: data?.workShiftDescription ?? "",
      workShiftType: data?.workShiftType ?? "",
      normalHours: data?.normalHours ?? "",
      workBreak: data?.workBreak ?? "",
      previousHours: data?.previousHours ?? "",
      laterHours: data?.laterHours ?? "",

      mondayType: data?.mondayType ?? "",
      monday1In: data?.monday1In ?? "",
      monday1Out: data?.monday1Out ?? "",
      monday2In: data?.monday2In ?? "",
      monday2Out: data?.monday2Out ?? "",
      monday3In: data?.monday3In ?? "",
      monday3Out: data?.monday3Out ?? "",
      monday4In: data?.monday4In ?? "",
      monday4Out: data?.monday4Out ?? "",

      tuesdayType: data?.tuesdayType ?? "",
      tuesday1In: data?.tuesday1In ?? "",
      tuesday1Out: data?.tuesday1Out ?? "",
      tuesday2In: data?.tuesday2In ?? "",
      tuesday2Out: data?.tuesday2Out ?? "",
      tuesday3In: data?.tuesday3In ?? "",
      tuesday3Out: data?.tuesday3Out ?? "",
      tuesday4In: data?.tuesday4In ?? "",
      tuesday4Out: data?.tuesday4Out ?? "",

      wednesdayType: data?.wednesdayType ?? "",
      wednesday1In: data?.wednesday1In ?? "",
      wednesday1Out: data?.wednesday1Out ?? "",
      wednesday2In: data?.wednesday2In ?? "",
      wednesday2Out: data?.wednesday2Out ?? "",
      wednesday3In: data?.wednesday3In ?? "",
      wednesday3Out: data?.wednesday3Out ?? "",
      wednesday4In: data?.wednesday4In ?? "",
      wednesday4Out: data?.wednesday4Out ?? "",

      thursdayType: data?.thursdayType ?? "",
      thursday1In: data?.thursday1In ?? "",
      thursday1Out: data?.thursday1Out ?? "",
      thursday2In: data?.thursday2In ?? "",
      thursday2Out: data?.thursday2Out ?? "",
      thursday3In: data?.thursday3In ?? "",
      thursday3Out: data?.thursday3Out ?? "",
      thursday4In: data?.thursday4In ?? "",
      thursday4Out: data?.thursday4Out ?? "",

      fridayType: data?.fridayType ?? "",
      friday1In: data?.friday1In ?? "",
      friday1Out: data?.friday1Out ?? "",
      friday2In: data?.friday2In ?? "",
      friday2Out: data?.friday2Out ?? "",
      friday3In: data?.friday3In ?? "",
      friday3Out: data?.friday3Out ?? "",
      friday4In: data?.friday4In ?? "",
      friday4Out: data?.friday4Out ?? "",

      saturdayType: data?.saturdayType ?? "",
      saturday1In: data?.saturday1In ?? "",
      saturday1Out: data?.saturday1Out ?? "",
      saturday2In: data?.saturday2In ?? "",
      saturday2Out: data?.saturday2Out ?? "",
      saturday3In: data?.saturday3In ?? "",
      saturday3Out: data?.saturday3Out ?? "",
      saturday4In: data?.saturday4In ?? "",
      saturday4Out: data?.saturday4Out ?? "",

      sundayType: data?.sundayType ?? "",
      sunday1In: data?.sunday1In ?? "",
      sunday1Out: data?.sunday1Out ?? "",
      sunday2In: data?.sunday2In ?? "",
      sunday2Out: data?.sunday2Out ?? "",
      sunday3In: data?.sunday3In ?? "",
      sunday3Out: data?.sunday3Out ?? "",
      sunday4In: data?.sunday4In ?? "",
      sunday4Out: data?.sunday4Out ?? "",
    },
  });

  const storeMutation = useMutation(storeWorkShift, {
    onSuccess: () => {
      toast.success("Turno criado com sucesso!");
      queryClient.invalidateQueries("work-shifts");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao criar turno.");
    },
  });

  const updateMutation = useMutation(updateWorkShift, {
    onSuccess: () => {
      toast.success("Turno atualizado com sucesso!");
      queryClient.invalidateQueries("work-shifts");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao atualizar turno.");
    },
  });

  const onSubmit = formData => {
    const payload = {
      companyCode: company,
      subsidiaryCode: subsidiary,

      id: formData.id,

      workShiftDescription: formData.workShiftDescription,
      workShiftType: formData.workShiftType,
      normalHours: formData.normalHours,
      workBreak: formData.workBreak,
      previousHours: formData.previousHours,
      laterHours: formData.laterHours,

      mondayType: formData.mondayType,
      monday1In: formData.monday1In,
      monday1Out: formData.monday1Out,
      monday2In: formData.monday2In,
      monday2Out: formData.monday2Out,
      monday3In: formData.monday3In,
      monday3Out: formData.monday3Out,
      monday4In: formData.monday4In,
      monday4Out: formData.monday4Out,

      tuesdayType: formData.tuesdayType,
      tuesday1In: formData.tuesday1In,
      tuesday1Out: formData.tuesday1Out,
      tuesday2In: formData.tuesday2In,
      tuesday2Out: formData.tuesday2Out,
      tuesday3In: formData.tuesday3In,
      tuesday3Out: formData.tuesday3Out,
      tuesday4In: formData.tuesday4In,
      tuesday4Out: formData.tuesday4Out,

      wednesdayType: formData.wednesdayType,
      wednesday1In: formData.wednesday1In,
      wednesday1Out: formData.wednesday1Out,
      wednesday2In: formData.wednesday2In,
      wednesday2Out: formData.wednesday2Out,
      wednesday3In: formData.wednesday3In,
      wednesday3Out: formData.wednesday3Out,
      wednesday4In: formData.wednesday4In,
      wednesday4Out: formData.wednesday4Out,

      thursdayType: formData.thursdayType,
      thursday1In: formData.thursday1In,
      thursday1Out: formData.thursday1Out,
      thursday2In: formData.thursday2In,
      thursday2Out: formData.thursday2Out,
      thursday3In: formData.thursday3In,
      thursday3Out: formData.thursday3Out,
      thursday4In: formData.thursday4In,
      thursday4Out: formData.thursday4Out,

      fridayType: formData.fridayType,
      friday1In: formData.friday1In,
      friday1Out: formData.friday1Out,
      friday2In: formData.friday2In,
      friday2Out: formData.friday2Out,
      friday3In: formData.friday3In,
      friday3Out: formData.friday3Out,
      friday4In: formData.friday4In,
      friday4Out: formData.friday4Out,

      saturdayType: formData.saturdayType,
      saturday1In: formData.saturday1In,
      saturday1Out: formData.saturday1Out,
      saturday2In: formData.saturday2In,
      saturday2Out: formData.saturday2Out,
      saturday3In: formData.saturday3In,
      saturday3Out: formData.saturday3Out,
      saturday4In: formData.saturday4In,
      saturday4Out: formData.saturday4Out,

      sundayType: formData.sundayType,
      sunday1In: formData.sunday1In,
      sunday1Out: formData.sunday1Out,
      sunday2In: formData.sunday2In,
      sunday2Out: formData.sunday2Out,
      sunday3In: formData.sunday3In,
      sunday3Out: formData.sunday3Out,
      sunday4In: formData.sunday4In,
      sunday4Out: formData.sunday4Out,
    };

    if (data?.id) {
      updateMutation.mutate(payload);
    } else {
      storeMutation.mutate(payload);
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {!data?.id ? "Adicionar Turno" : "Editar Turno"}
      </DialogTitle>

      <DialogContent dividers>
        {/* ---- Campos de texto (exemplo com TextField + Controller) ---- */}
        <div className="row">
          <div className="col-md-12">
            <Controller
              name="workShiftDescription"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label="Descrição do turno"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  required
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <Controller
              name="workShiftType"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  required
                  error={!!fieldState.error}
                >
                  <InputLabel>Tipo de turno</InputLabel>
                  <Select {...field} label="Tipo de turno">
                    {workShiftTypeOptions.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldState.error && (
                    <p style={{ color: "red", marginTop: 0 }}>
                      {fieldState.error.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </div>
        </div>

        {/* ---- Campos de hora mascarados (novo WorkshiftHourMask) ---- */}
        <div className="row">
          <div className="col-md-6">
            <WorkshiftHourMask
              name="normalHours"
              control={control}
              label="Horas normais"
              margin="normal"
              fullWidth
              required
            />
          </div>
          <div className="col-md-6">
            <WorkshiftHourMask
              name="workBreak"
              control={control}
              label="Intervalo"
              margin="normal"
              fullWidth
              required
            />
          </div>
        </div>

        <Alert severity="info" sx={{ marginTop: 4 }}>
          Quantas horas antes ou depois do horário o funcionário pode registrar
          o ponto?
        </Alert>

        <div className="row">
          <div className="col-md-6">
            <WorkshiftHourMask
              name="previousHours"
              control={control}
              label="Horas antes"
              margin="normal"
              fullWidth
              required
            />
          </div>
          <div className="col-md-6">
            <WorkshiftHourMask
              name="laterHours"
              control={control}
              label="Horas depois"
              margin="normal"
              fullWidth
              required
            />
          </div>
        </div>

        {/* ---- Tabela de dias da semana ---- */}
        <Accordion defaultExpanded sx={{ marginTop: 4 }}>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Dia da Semana</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>1ª Entrada</TableCell>
                        <TableCell>1ª Saída</TableCell>
                        <TableCell>2ª Entrada</TableCell>
                        <TableCell>2ª Saída</TableCell>
                        <TableCell>3ª Entrada</TableCell>
                        <TableCell>3ª Saída</TableCell>
                        <TableCell>4ª Entrada</TableCell>
                        <TableCell>4ª Saída</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {days.map((day, i) => (
                        <TableRow key={day}>
                          <TableCell>{daysDescription[i]}</TableCell>

                          {/* Tipo do dia */}
                          <TableCell>
                            <Controller
                              name={`${day}Type`}
                              control={control}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  variant="standard"
                                  select
                                  fullWidth
                                  required
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                >
                                  {typeOfDayOptions.map(opt => (
                                    <MenuItem key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </TableCell>

                          {/* Entradas/Saídas */}
                          {[1, 2, 3, 4].map(punch => (
                            <React.Fragment key={punch}>
                              <TableCell>
                                <WorkshiftHourMask
                                  name={`${day}${punch}In`}
                                  control={control}
                                  margin="normal"
                                  required
                                  fullWidth
                                />
                              </TableCell>
                              <TableCell>
                                <WorkshiftHourMask
                                  name={`${day}${punch}Out`}
                                  control={control}
                                  margin="normal"
                                  required
                                  fullWidth
                                />
                              </TableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.id ? "Adicionar" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
