import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import {
  storeCostCenter,
  updateCostCenter,
  getCostCenterType,
} from "../../../../../services/pwsApi";

const costCenterSchema = object().shape({
  costCenterCode: string().required("Informe o código do centro de custo."),
  costCenterName: string().required("Informe o nome do centro de custo."),
  classOption: string().required("Selecione Sintético ou Analítico."),
  costCenterType: string().required("Selecione o tipo de centro de custo."),
});

export function CostCentersDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");
  const company = companyCode?.slice(0, 3);
  const subsidiary = companyCode?.slice(3, 7);

  const [costCenterTypeOptions, setCostCenterTypeOptions] = useState([]);

  const classOptions = [
    { value: "1", label: "Sintético" },
    { value: "2", label: "Analítico" },
  ];

  useEffect(() => {
    if (company && subsidiary) {
      getCostCenterType(company, subsidiary)
        .then(response => {
          setCostCenterTypeOptions(
            response.data.data.map(costCenterType => ({
              value: costCenterType.COD,
              label: costCenterType.CONTEUDO,
            })),
          );
        })
        .catch(() => {
          toast.error("Erro ao carregar tipos de centro de custo.");
        });
    }
  }, [company, subsidiary]);

  useEffect(() => {
    if (data) {
      reset({
        costCenterId: data.costCenterId ?? null,
        costCenterCode: data.costCenterCode ?? "",
        costCenterName: data.costCenterName ?? "",
        classOption: String(data.classOption) ?? "",
        costCenterType: String(data.costCenterType) ?? "",
      });
    }
  }, [data, reset]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(costCenterSchema),
    defaultValues: {
      costCenterId: data?.costCenterId ?? null,
      costCenterCode: data?.costCenterCode ?? "",
      costCenterName: data?.costCenterName ?? "",
      classOption: data?.classOption ?? "",
      costCenterType: data?.costCenterType ?? "",
    },
  });

  const storeMutation = useMutation(storeCostCenter, {
    onSuccess: () => {
      toast.success("Centro de custo criado com sucesso.");
      queryClient.invalidateQueries("cost-centers");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao criar centro de custo.");
    },
  });

  const updateMutation = useMutation(
    variables => updateCostCenter(variables.costCenterId, variables),
    {
      onSuccess: () => {
        toast.success("Centro de custo atualizado com sucesso.");
        queryClient.invalidateQueries("cost-centers");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao atualizar centro de custo.");
      },
    },
  );

  const onSubmit = formData => {
    const {
      costCenterId,
      costCenterCode,
      costCenterName,
      classOption,
      costCenterType,
    } = formData;

    const payload = {
      companyCode: company,
      subsidiaryCode: subsidiary,
      costCenterCode,
      costCenterName,
      classOption,
      costCenterType,
    };

    if (costCenterId) {
      updateMutation.mutate({ ...payload, costCenterId });
    } else {
      storeMutation.mutate(payload);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>
        {!data?.costCenterId
          ? "Adicionar Centro de Custo"
          : "Editar Centro de Custo"}
      </DialogTitle>

      <DialogContent dividers>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="costCenterCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Código do Centro de Custo"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.costCenterCode}
                  helperText={errors.costCenterCode?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="costCenterName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome do Centro de Custo"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.costCenterName}
                  helperText={errors.costCenterName?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="classOption"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.classOption}
                >
                  <InputLabel>Classificação</InputLabel>
                  <Select {...field} label="Classificação">
                    {classOptions.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.classOption && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.classOption.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="costCenterType"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.costCenterType}
                >
                  <InputLabel>Tipo do Centro de Custo</InputLabel>
                  <Select
                    {...field}
                    label="Tipo do Centro de Custo"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: "200px",
                          maxWidth: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        },
                      },
                    }}
                  >
                    {costCenterTypeOptions.map(opt => (
                      <MenuItem
                        key={opt.value}
                        value={opt.value}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          overflowWrap: "anywhere",
                          lineHeight: "1.5",
                        }}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.costCenterType && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.costCenterType.message}
              </p>
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.costCenterId ? "Adicionar" : "Editar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
