import * as yup from "yup";

export const paymentSchema = yup.object().shape({
  // Campos do tipo 'select'
  usesBankTransferFile: yup
    .string()
    .required("Seleção sobre transferência bancária é obrigatória"),
  isBankFileLayoutDPlusOne: yup
    .string()
    .required("Seleção sobre layout do arquivo bancário D+1 é obrigatória"),
  hasSequentialNumberRule: yup
    .string()
    .required("Seleção sobre regra de número sequencial é obrigatória"),
  hasSpecificBusinessRule: yup
    .string()
    .required("Seleção sobre regra específica é obrigatória"),
  anticipatesVacationDiscounts: yup
    .string()
    .required(
      "Seleção sobre antecipação de descontos nas férias é obrigatória",
    ),
  hasSpecificDiscountRule: yup
    .string()
    .required("Seleção sobre regra específica de desconto é obrigatória"),
  usesAverages: yup
    .string()
    .required("Seleção sobre uso de médias é obrigatória"),
  hasVacationBenefit: yup
    .string()
    .required("Seleção sobre benefício concedido nas férias é obrigatória"),

  // Campos do tipo 'text'
  bankName: yup.string().required("Nome do banco é obrigatório"),
  bankBranch: yup
    .string()
    .matches(/^\d+$/, "A agência deve conter apenas números")
    .required("Agência é obrigatória"),
  accountNumber: yup
    .string()
    .matches(/^\d+$/, "A conta deve conter apenas números")
    .required("Número da conta é obrigatório"),
  agreementCode: yup
    .string()
    .matches(/^\d+$/, "O código do convênio deve conter apenas números")
    .required("Código do convênio é obrigatório"),
});
