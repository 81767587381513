import React from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function BenefitsStep1() {
  const { control } = useFormContext();
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="healthPlanBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="health-plan-benefits-1-label">
                    Possui plano de saúde?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="health-plan-benefits-1-label"
                    id="health-plan-benefits1"
                    variant="outlined"
                    label="Possui plano de saúde?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasMultipleContractsHealthBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-multiple-contracts-health-benefits-1-label">
                    Possui mais de um contrato?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-multiple-contracts-health-benefits-1-label"
                    id="has-multiple-contracts-health-benefits-1"
                    variant="outlined"
                    label="Possui mais de um contrato?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="contractTypeHealthBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="contract-type-health-benefits-1-label">
                    Contrato tipo plano:
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="contract-type-health-benefits-1-label"
                    id="contract-type-health-benefits-1"
                    variant="outlined"
                    label="Contrato tipo plano"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="companyCostPercentageHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo da empresa"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="employeeCostPercentageBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do colaborador"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="dependentCostPercentageHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do dependente"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="additionalDependentCostPercentageHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do agregado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasCoparticipationHealthBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-coparticipation-health-benefits-1-label">
                    Possui coparticipação?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-coparticipation-health-benefits-1-label"
                    id="has-coparticipation-health-benefits-1"
                    variant="outlined"
                    label="Possui coparticipação?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="companyCostPercentageCoparticipationHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo da empresa"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="employeeCostPercentageCoparticipationHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do colaborador"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="dependentCostPercentageCoparticipationHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do dependente"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="additionalDependentCostPercentageCoparticipationHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do agregado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="nonEmployeeBeneficiariesHealthBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existem beneficiários do plano de saúde que não constam no quadro de colaboradores e seus respectivos dependentes/agregados?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Plano Odontológico
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasDentalPlanBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-dental-plan-benefits-1-label">
                    Possui plano odontológico?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-dental-plan-benefits-1-label"
                    id="has-dental-plan-benefits-1"
                    variant="outlined"
                    label="Possui plano odontológico?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="contractTypeDentalBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="contract-type-dental-benefits-1-label">
                    Tipo do plano odontológico
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="contract-type-dental-benefits-1-label"
                    id="contract-type-dental-benefits-1"
                    variant="outlined"
                    label="Tipo do plano odontológico"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="companyCostPercentageDentalBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo da empresa"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="employeeCostPercentageDentalBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do colaborador"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="dependentCostPercentageDentalBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do dependente"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="additionalDependentCostPercentageDentalBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="% de custo do agregado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="nonEmployeeBeneficiariesDentalBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="non-employee-beneficiaries-dental-benefits-1-label">
                    Existem beneficiários do plano odontológico que não constam
                    no quadro de colaboradores e seus respectivos
                    dependentes/agregados?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="non-employee-beneficiaries-dental-benefits-1-label"
                    id="non-employee-beneficiaries-dental-benefits-1"
                    variant="outlined"
                    label="Existem beneficiários do plano odontológico que não constam no quadro de colaboradores e seus respectivos dependentes/agregados?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Vale Transporte
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasTransportationVoucherBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-transportation-voucher-benefits-1">
                    Possui vale transporte?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-transportation-voucher-benefits-1"
                    id="has-transportation-voucher-benefits-1"
                    variant="outlined"
                    label="Possui vale transporte?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="transportationApportionPeriodBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="transportation-apportion-period-benefits-1-label">
                    Período de apuração
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="transportation-apportion-period-benefits-1-label"
                    id="transportation-apportion-period-benefits-1"
                    variant="outlined"
                    label="Período de apuração"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Período de deduções"
                  fullWidth
                  name="transportationDeductionPeriodBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="transportationDeductionPeriodBenefits1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="executedTransportationCalculationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cálculo do benefício executado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data limite de compra do benefício"
                  fullWidth
                  name="transportationPurchaseDeadlineBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="transportationPurchaseDeadlineBenefits1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductAbsencesTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Faltas são deduzidas do cálculo?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductCertificatesTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deduct-certificates-transportation-benefits-1-label">
                    Atestados são deduzidos do cálculo?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deduct-certificates-transportation-benefits-1-label"
                    id="deduct-certificates-transportation-benefits-1"
                    variant="outlined"
                    label="Atestados são deduzidos do cálculo?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="accidentLeavesReceiveTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="accident-leaves-receive-transportation-benefits-1-label">
                    Afastados por acidente recebem o benefício?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="accident-leaves-receive-transportation-benefits-1-label"
                    id="accident-leaves-receive-transportation-benefits-1"
                    variant="outlined"
                    label="Afastados por acidente recebem o benefício?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="otherDeductionFactorsTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existem outros fatores de dedução do cálculo do benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="Afastados por doença recebem o benefício?"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="sick-leaves-receive-transportation-benefits-1-label">
                    Relatório analítico por ordem de:
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="sick-leaves-receive-transportation-benefits-1-label"
                    id="sick-leaves-receive-transportation-benefits-1"
                    variant="outlined"
                    label="Relatório analítico por ordem de:"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="payrollDeductionTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Desconto em folha de pagamento"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="maternityLeaveReceiveTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Licença maternidade, recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="vacationReceiveTransportationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Férias, recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductUnusedTransportationOnResignationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sobre a rescisão, desconta o benefício não utilizado?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Vale Refeição
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasMealVoucherBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-meal-voucher-benefits-1-label">
                    Possui vale refeição?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-meal-voucher-benefits-1-label"
                    id="has-meal-voucher-benefits-1"
                    variant="outlined"
                    label="Possui vale refeição?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Período de apuração"
                  fullWidth
                  name="calculationApportionPeriodMealBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="calculationApportionPeriodMealBenefits1"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Período de deduções"
                  fullWidth
                  name="deductionApportionPeriodMealBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="deductionApportionPeriodMealBenefits1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="executedCalculationMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cálculo do benefício executado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data limite de compra do benefício"
                  fullWidth
                  name="purchaseDeadlineMealBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="purchaseDeadlineMealBenefits1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="benefitTypeAndValueMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tipo benefício e valor"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="dailyOrFixedValueMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Informe se é por dias úteis e o valor ou fixo e o valor"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="proportionalCalculation31DaysMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cálculo proporcional em meses com 31 dias divide-se por 31"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductAbsencesMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Faltas são deduzidas do cálculo?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductCertificatesMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Atestados são deduzidos do cálculo?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="sickLeavesReceiveMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Afastados por doença recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="otherDeductionFactorsMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existem outros fatores de dedução do cálculo do benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="maternityLeaveReceiveMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Licença maternidade, recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="vacationReceiveMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Férias, recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductUnusedMealOnResignationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sobre a rescisão, desconta o benefício não utilizado?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="payrollDeductionMealBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Desconto em folha de pagamento"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Vale Alimentação
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasFoodVoucherBenefits1"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-food-voucher-benefits-1-label">
                    Possui vale refeição?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-food-voucher-benefits-1-label"
                    id="has-food-voucher-benefits-1"
                    variant="outlined"
                    label="Possui vale alimentação?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Período de apuração"
                  fullWidth
                  name="calculationApportionPeriodFoodBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="calculationApportionPeriodFoodBenefits1"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Período de deduções"
                  fullWidth
                  name="deductionApportionPeriodFoodBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="deductionApportionPeriodFoodBenefits1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="executedCalculationFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cálculo do benefício executado"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data limite de compra do benefício"
                  fullWidth
                  name="purchaseDeadlineFoodBenefits1"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="purchaseDeadlineFoodBenefits1"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="benefitTypeAndValueFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tipo benefício e valor"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="dailyOrFixedValueFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Informe se é por dias úteis e o valor ou fixo e o valor"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="proportionalCalculation31DaysFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cálculo proporcional em meses com 31 dias divide-se por 31"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductAbsencesFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Faltas são deduzidas do cálculo?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductCertificatesFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Atestados são deduzidos do cálculo?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="sickLeavesReceiveFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Afastados por doença recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="otherDeductionFactorsFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existem outros fatores de dedução do cálculo do benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="maternityLeaveReceiveFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Licença maternidade, recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="vacationReceiveFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Férias, recebem o benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductUnusedFoodOnResignationBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Sobre a rescisão, desconta o benefício não utilizado?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="payrollDeductionFoodBenefits1"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Desconto em folha de pagamento?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
