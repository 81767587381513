import React from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function Payroll() {
  const { control } = useFormContext();
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="internAdjustmentByPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="intern-adjustment-by-payroll-label">
                    Estagiários têm reajuste por?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="intern-adjustment-by-payroll-label"
                    id="intern-adjustment-by-payroll"
                    variant="outlined"
                    label="Estagiários têm reajuste por?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductsForInternPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deducts-for-intern-payroll-label">
                    Efetua descontos para estagiário?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deducts-for-intern-payroll-label"
                    id="deducts-for-intern-payroll"
                    variant="outlined"
                    label="Efetua descontos para estagiário?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="proportionalDivisionDaysPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="proportional-division-days-payroll-label">
                    Quando proporcional divide-se por quantos dias?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="proportional-division-days-payroll-label"
                    id="proportional-division-days-payroll"
                    variant="outlined"
                    label="Quando proporcional divide-se por quantos dias?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="paysFixedBenefitPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="pays-fixed-benefit-payroll-label">
                    Paga-se benefício fixo?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="pays-fixed-benefit-payroll-label"
                    id="pays-fixed-benefit-payroll-label"
                    variant="outlined"
                    label="Paga-se benefício fixo?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="benefitValuePayroll"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Valor do benefício"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Aprendiz
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="adjustmentByPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="adjustment-by-payroll-label">
                    Reajuste por?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="adjustment-by-payroll-label"
                    id="adjustment-reason-for-apprentice"
                    variant="outlined"
                    label="Reajuste por?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductsForApprenticePayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deducts-for-apprentice-payroll-label">
                    Efetua descontos para aprendiz?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deducts-for-apprentice-payroll-label"
                    id="deducts-for-apprentice-payroll"
                    variant="outlined"
                    label="Efetua descontos para aprendiz? "
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="businessRuleDescriptionPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="business-rule-description-payroll-label">
                    Descreva verbas/rubricas e regra de negócio
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="business-rule-description-payroll-label"
                    id="business-rule-description-payroll"
                    variant="outlined"
                    label="Descreva verbas/rubricas e regra de negócio"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="paysFixedBenefitApprenticePayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="pays-fixed-benefit-apprentice-payroll-label">
                    Paga-se benefício fixo?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="pays-fixed-benefit-apprentice-payroll-label"
                    id="pays-fixed-benefit-apprentice-payroll"
                    variant="outlined"
                    label="Paga-se benefício fixo?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="apprenticeProportionalDivisionDaysPayroll"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Quando proporcional divide-se por quantos dias?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="apprenticeBenefitValuePayroll"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Valor do benefício?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Contribuinte Individual
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="selfEmployedPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="self-employed-payroll-label">
                    Autônomos
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="self-employed-payroll-label"
                    id="self-employed-payroll"
                    variant="outlined"
                    label="Autônomos"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="proLaborPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="pro-labor-payroll-label">
                    Pró-labore
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="pro-labor-payroll-label"
                    id="pro-labor-payroll"
                    variant="outlined"
                    label="Pró-labore"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="adjustmentByProLaborPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="adjustment-by-pro-labor-payroll-label">
                    Reajuste por?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="adjustment-by-pro-labor-payroll-label"
                    id="adjustment-by-pro-labor-payroll"
                    variant="outlined"
                    label="Reajuste por?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="fgtsWithoutLinkPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="fgts-without-link-payroll-label">
                    Com FGTS sem vínculo?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="fgts-without-link-payroll-label"
                    id="fgts-without-link-payroll-label"
                    variant="outlined"
                    label="Com FGTS sem vínculo?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Relatórios
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="analyticalReportByOrderOfPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="analytical-report-by-order-of-payroll-label">
                    Relatório analítico por ordem de:
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="analytical-report-by-order-of-payroll-label"
                    id="analytical-report-order"
                    variant="outlined"
                    label="Relatório analítico por ordem de:"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="syntheticReportByOrderOfPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="synthetic-report-by-order-of-payroll-label">
                    Relatório sintético por ordem de:
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="synthetic-report-by-order-of-payroll-label"
                    id="synthetic-report-by-order-of-payroll"
                    variant="outlined"
                    label="Relatório sintético por ordem de:"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="specificRubricsPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="specific-rubrics-payroll-label">
                    A empresa possui verbas/rubricas peculiares ao negócio?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="specific-rubrics-payroll-label"
                    id="specific-rubrics-payroll"
                    variant="outlined"
                    label="A empresa possui verbas/rubricas peculiares ao negócio?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Benefícios
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="lifeInsurancePayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="life-insurance-payroll-label">
                    Seguro de vida
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="life-insurance-payroll-label"
                    id="life-insurance-payroll"
                    variant="outlined"
                    label="Seguro de vida"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="executedMovementsPayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="executed-movements-payroll-label">
                    Movimentação executada?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="executed-movements-payroll-label"
                    id="executed-movements-payroll"
                    variant="outlined"
                    label="Movimentação executada?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Prazo de envio do relatório de movimentação"
                  fullWidth
                  name="movementReportDeadlinePayroll"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="movementReportDeadlinePayroll"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="discountValuePayroll"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="discount-value-payroll-label">
                    Valor desconto?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="discount-value-payroll-label"
                    id="discount-value-payroll"
                    variant="outlined"
                    label="Valor desconto?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="businessRuleDescriptionLifeInsurancePayroll"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descreva a regra de negócio"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Custos
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="companyCostPayroll"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Custo empresa"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="employeeCostPayroll"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Custo colaborador"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
