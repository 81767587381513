import React, { useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  Fab,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Add, Close, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { LoadingBox } from "../../../../../components/utils";
import DepartmentsTable from "./components/DepartmentsTable";
import { DepartmentsDialog } from "./components/DepartmentsDialog";
import { getDepartments, getDepartment } from "../../../../services/pwsApi";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Departments() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const companyCode = searchParams.get("company_code");
  const departmentId = searchParams.get("department_id");

  const tablePageIndex = Number(searchParams.get("page") ?? 1);
  const tablePageSize = Number(searchParams.get("page_size") ?? 50);

  const departmentsQuery = useQuery(
    ["departments", companyCode, tablePageIndex, tablePageSize, searchQuery],
    () =>
      getDepartments(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        searchQuery,
        {
          mostrarFuncao: true,
          mostrarCentroCusto: true,
          page: tablePageIndex,
          perPage: tablePageSize,
        },
      ),
    {
      enabled: !!companyCode,
    },
  );

  const departmentQuery = useQuery(
    ["department", departmentId],
    () =>
      getDepartment(
        companyCode.slice(0, 3),
        companyCode.slice(3, 7),
        departmentId,
      ),
    {
      enabled: !!departmentId,
      onSuccess: () => setDialogOpen(true),
    },
  );

  let department = departmentQuery.data?.data?.data;
  department = department
    ? {
        departmentId: department.QB_DEPTO,
        departmentCode: department.QB_DEPTO,
        departmentName: department.QB_DESCRIC,
      }
    : null;

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const handleCloseUserDialog = () => {
    searchParams.delete("department_id");
    setSearchParams(searchParams);

    setDialogOpen(false);
  };

  const handleCreateDeparment = () => {
    setDialogOpen(true);
  };

  const searchDelayed = debounce(query => {
    setSearchQuery(query);
  }, 1000);

  const departments = departmentsQuery?.data?.data?.data ?? [];
  const rows = departmentsQuery?.data?.data?.meta?.total ?? 0;

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      {dialogOpen && (
        <DepartmentsDialog onClose={handleCloseUserDialog} data={department} />
      )}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Departamentos
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={departmentsQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Box sx={{ height: "calc(100% - 94px)", padding: "15px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            style={{ width: "400px" }}
            label="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              searchDelayed(event.target.value);
            }}
          />
        </Box>
        <DepartmentsTable data={departments} rows={rows} />
      </Box>
      {/* <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleCreateDeparment}
      >
        <Add />
      </Fab> */}
    </Dialog>
  );
}
