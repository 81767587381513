import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import { storeFunction, updateFunction } from "../../../../../services/pwsApi";

const functionSchema = object().shape({
  functionName: string().required("Informe o nome da função."),
  cbo: string().required("Informe o CBO da função."),
});

export function FunctionsDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code") || "";
  const company = companyCode.slice(0, 3);
  const subsidiary = companyCode.slice(3, 7);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(functionSchema),
    defaultValues: {
      functionId: data?.functionId ?? null,
      functionName: data?.functionName ?? "",
      cbo: data?.cbo ?? "",
    },
  });

  const storeMutation = useMutation(storeFunction, {
    onSuccess: () => {
      toast.success("Função criada com sucesso.");
      queryClient.invalidateQueries("functions");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao criar função.");
    },
  });

  const updateMutation = useMutation(
    variables => updateFunction(variables.functionId, variables),
    {
      onSuccess: () => {
        toast.success("Função atualizada com sucesso.");
        queryClient.invalidateQueries("functions");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao atualizar função.");
      },
    },
  );

  const onSubmit = formData => {
    const { functionId, functionName, cbo } = formData;

    const payload = {
      companyCode: company,
      subsidiaryCode: subsidiary,
      functionName,
      cbo,
    };

    if (functionId) {
      updateMutation.mutate({ functionId, ...payload });
    } else {
      storeMutation.mutate(payload);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>
        {!data?.functionId ? "Adicionar Função" : "Editar Função"}
      </DialogTitle>

      <DialogContent dividers>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="functionName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome da Função"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.functionName}
                  helperText={errors.functionName?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="cbo"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="CBO da Função"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.cbo}
                  helperText={errors.cbo?.message}
                />
              )}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.functionId ? "Adicionar" : "Editar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
