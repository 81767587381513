import React, { useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Dialog,
  Fab,
  IconButton,
  InputAdornment,
  Slide,
  TextField,
  Toolbar,
  Typography,
  debounce,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Add, Close, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import { LoadingBox } from "../../../../../components/utils";
import MobileUserTable from "./components/MobileUserTable";
import { getUser, getUsers } from "../../../../services/mobileApi";
import { MobileUserDialog } from "./components/MobileUserDialog";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MobileUsers() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const companyCode = searchParams.get("company_code");
  const userId = searchParams.get("user_id");

  const tablePageIndex = Number(searchParams.get("page") ?? 1);
  const tablePageSize = Number(searchParams.get("page_size") ?? 50);

  const usersQuery = useQuery(
    ["mobile-users", companyCode, tablePageIndex, tablePageSize, searchQuery],
    () =>
      getUsers({
        company_code: companyCode.slice(0, 3),
        subsidiary_code: companyCode.slice(3, 7),
        q: searchQuery
      }),
    {
      enabled: !!companyCode,
    },
  );

  const userQuery = useQuery(
    ["mobile-users", userId],
    () => getUser(companyCode.slice(0, 3), companyCode.slice(3, 7), userId),
    {
      enabled: !!userId,
      onSuccess: () => setDialogOpen(true),
    },
  );
  let user = userQuery.data?.data;
  user = user
    ? {
        id: user.user.id,
        enrollNumber: JSON.parse(user.data).enroll_number,
        cpf: user.user.cpf,
        email: user.user.email,
        checked: !!user.user.email_verified_at,
      }
    : null;

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const handleCloseUserDialog = () => {
    searchParams.delete("user_id");
    setSearchParams(searchParams);

    setDialogOpen(false);
  };

  const handleCreateUser = () => {
    setDialogOpen(true);
  };

  const searchDelayed = debounce(query => {
    setSearchQuery(query);
  }, 1000);

  const users = usersQuery?.data?.data?.data ?? [];
  const rows = usersQuery?.data?.data?.meta?.total ?? 0;

  return (
    <Dialog
      fullScreen
      open
      onClose={handleCloseDialog}
      TransitionComponent={Transition}
    >
      {dialogOpen && (
        <MobileUserDialog onClose={handleCloseUserDialog} data={user} />
      )}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Usuários aplicativo
          </Typography>
        </Toolbar>
      </AppBar>
      <Backdrop
        className={classes.backdrop}
        open={usersQuery?.isLoading ?? true}
      >
        <LoadingBox />
      </Backdrop>
      <Box sx={{ height: "calc(100% - 94px)", padding: "15px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <TextField
            style={{ width: "400px" }}
            label="Pesquisar"
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={event => {
              searchDelayed(event.target.value);
            }}
          />
        </Box>
        <MobileUserTable data={users} rows={rows} />
      </Box>
      <Fab
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={handleCreateUser}
      >
        <Add />
      </Fab>
    </Dialog>
  );
}
