// WorkshiftHourMask.jsx
import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import MaskedInput from "react-text-mask";
import { useController } from "react-hook-form";

const WorkshiftHourMaskBase = forwardRef(function WorkshiftHourMaskBase(
  props,
  ref,
) {
  return (
    <MaskedInput
      {...props}
      ref={ref}
      mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      guide={false}
      keepCharPositions={false}
      placeholderChar={"\u2000"}
      showMask
    />
  );
});

export default function WorkshiftHourMask(props) {
  const { name, control, label, ...rest } = props;

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ name, control });

  const hasError = !!error && isTouched;

  return (
    <TextField
      variant="standard"
      {...rest}
      label={label}
      {...field}
      type="time"
      error={hasError}
      helperText={hasError ? error.message : null}
      InputProps={{
        ...field,
        inputComponent: WorkshiftHourMaskBase,
      }}
    />
  );
}
