import React from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function Payment() {
  const { control } = useFormContext();
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="usesBankTransferFile"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="uses-bank-transfer-file-payment-label">
                    A empresa efetua os pagamentos via arquivo de transferência
                    bancária (CNAB)?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="uses-bank-transfer-file-payment-label"
                    id="usesBankTransferFilePayment"
                    variant="outlined"
                    label="A empresa efetua os pagamentos via arquivo de transferência bancária (CNAB)?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="bankFileLayoutRuleDPlusOnePayment"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="bank-file-layout-rule-d-plus-one-payment-label">
                    Layout arquivo bancário é regra de pagamento D+1?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="bank-file-layout-rule-d-plus-one-payment-label"
                    id="bank-file-layout-rule-d-plus-one-payment"
                    variant="outlined"
                    label="Layout arquivo bancário é regra de pagamento D+1?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="sequentialNumberRulePayment"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="sequential-number-rule-payment-label">
                    Regra de número sequencial?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="sequential-number-rule-payment-label"
                    id="sequential-number-rule-payment"
                    variant="outlined"
                    label="Regra de número sequencial?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="specificBusinessRulePayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existe regra específica? Se existir, descreva a regra de negócio"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Dados bancários
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="bankPayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Banco"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="bankAgencyPayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Agência"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="bankAccountPayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Conta"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="agreementCodePayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cod. Convenio"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Férias
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="advancesDiscountsOnVacationPayment"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="advances-discounts-on-vacation-payment-label">
                    Antecipa descontos no cálculo de férias
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="advances-discounts-on-vacation-payment-label"
                    id="advances-discounts-on-vacation-payment"
                    variant="outlined"
                    label="Antecipa descontos no cálculo de férias"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="specificDiscountRulePayment"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="specific-discount-rule-payment-label">
                    Regra específica para aplicar o desconto?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="specific-discount-rule-payment-label"
                    id="has-specific-discount-rule"
                    variant="outlined"
                    label="Regra específica para aplicar o desconto?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="averagesPayment"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="averages-payment-label">Médias?</InputLabel>
                  <Select
                    {...field}
                    labelId="averages-payment-label"
                    id="averages-payment"
                    variant="outlined"
                    label="Médias?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="vacationBenefitPayment"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="vacation-benefit-payment-label">
                    Existe benefício concedido quando do cálculo das férias?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="vacation-benefit-payment-label"
                    id="vacation-benefit-payment"
                    variant="outlined"
                    label="Existe benefício concedido quando do cálculo das férias?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
