import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import {
  storeSyndicate,
  updateSyndicate,
  getStreetTypes,
  getState,
  getCities,
} from "../../../../../services/pwsApi";

const syndicateSchema = object().shape({
  cnpj: string()
    .required("Informe o CNPJ.")
    .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, "CNPJ inválido."),
  syndicateName: string().required("Informe o nome do sindicato."),
  entityCOD: string().required("Informe o código de entidade."),
  zipCode: string().required("Informe o CEP."),
  address: string().required("Informe o endereço."),
  addressNumber: string().required("Informe o número."),
  addressComplement: string().nullable(),
  addressNeighborhood: string().required("Informe o bairro."),
  addressState: string().required("Selecione o estado."),
  addressCity: string().required("Selecione a cidade."),
  email: string()
    .email("E-mail inválido")
    .required("Informe o e-mail."),
  phone: string().required("Informe o telefone."),
  cellphone: string().nullable(),
});

export function SyndicatesDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code") || "";
  const company = companyCode.slice(0, 3);
  const subsidiary = companyCode.slice(3, 7);

  const [streetTypesOptions, setStreetTypesOptions] = useState([]);
  const [addressStateOptions, setAddressStateOptions] = useState([]);
  const [addressCityOptions, setAddressCityOptions] = useState([]);

  useEffect(() => {
    if (company && subsidiary) {
      getStreetTypes(company, subsidiary)
        .then(response => {
          console.log(response);
          setStreetTypesOptions(
            response.data.data.map(type => ({
              value: type?.COD,
              label: type?.CONTEUDO,
            })),
          );
        })
        .catch(() => toast.error("Erro ao carregar tipos de logradouro."));

      getState(company, subsidiary)
        .then(response => {
          setAddressStateOptions(
            response.data.data.map(st => ({
              value: st?.X5_CHAVE,
              label: st?.X5_DESCRI,
            })),
          );
        })
        .catch(() => toast.error("Erro ao carregar estados."));
    }
  }, [company, subsidiary]);

  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    if (company && subsidiary && selectedState) {
      getCities(company, subsidiary, selectedState)
        .then(response => {
          setAddressCityOptions(
            response.data.data.map(city => ({
              value: city?.CC2_CODMUN,
              label: city?.CC2_MUN,
            })),
          );
        })
        .catch(() => toast.error("Erro ao carregar cidades."));
    }
  }, [company, subsidiary, selectedState]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(syndicateSchema),
    defaultValues: {
      syndicateId: data?.syndicateId ?? null,
      cnpj: data?.cnpj ?? "",
      syndicateName: data?.syndicateName ?? "",
      entityCOD: data?.entityCOD ?? "",
      zipCode: data?.zipCode ?? "",
      address: data?.address ?? "",
      addressNumber: data?.addressNumber ?? "",
      addressComplement: data?.addressComplement ?? "",
      addressNeighborhood: data?.addressNeighborhood ?? "",
      addressState: data?.addressState ?? "",
      addressCity: data?.addressCity ?? "",
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      cellphone: data?.cellphone ?? "",
    },
  });

  const addressStateValue = watch("addressState");
  useEffect(() => {
    if (addressStateValue) {
      setSelectedState(addressStateValue);
    }
  }, [addressStateValue]);

  useEffect(() => {
    if (addressCityOptions.length > 0 && data?.addressCity) {
      const city = addressCityOptions.find(
        opt => opt.label === data.addressCity,
      );
      if (city) {
        setValue("addressCity", city.value);
      }
    }
  }, [addressCityOptions, data, setValue]);

  const storeMutation = useMutation(storeSyndicate, {
    onSuccess: () => {
      toast.success("Sindicato criado com sucesso.");
      queryClient.invalidateQueries("syndicates");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao criar sindicato.");
    },
  });

  const updateMutation = useMutation(updateSyndicate, {
    onSuccess: () => {
      toast.success("Sindicato atualizado com sucesso.");
      queryClient.invalidateQueries("syndicates");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao atualizar sindicato.");
    },
  });

  const onSubmit = formData => {
    const {
      companyCode: company,
      subsidiaryCode: subsidiary,
      syndicateId,
      cnpj,
      syndicateName,
      entityCOD,
      zipCode,
      address,
      addressNumber,
      addressComplement,
      addressNeighborhood,
      addressState,
      addressCity,
      email,
      phone,
      cellphone,
    } = formData;

    const payload = {
      companyCode: company,
      subsidiaryCode: subsidiary,
      cnpj,
      syndicateName,
      entityCOD,
      zipCode,
      address,
      addressNumber,
      addressComplement,
      addressNeighborhood,
      addressState,
      addressCity,
      email,
      phone,
      cellphone,
    };

    if (syndicateId) {
      updateMutation.mutate({ syndicateId, ...payload });
    } else {
      storeMutation.mutate(payload);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>
        {!data?.syndicateId ? "Adicionar Sindicato" : "Editar Sindicato"}
      </DialogTitle>

      <DialogContent dividers>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="cnpj"
              control={control}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label="CNPJ"
                    margin="normal"
                    fullWidth
                    variant="standard"
                    error={!!errors.cnpj}
                    helperText={errors.cnpj?.message}
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="syndicateName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome do Sindicato"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.syndicateName}
                  helperText={errors.syndicateName?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="entityCOD"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Cód. Entidade"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.entityCOD}
                  helperText={errors.entityCOD?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="CEP"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.zipCode}
                  helperText={errors.zipCode?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Endereço"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="addressNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Número"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.addressNumber}
                  helperText={errors.addressNumber?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="addressComplement"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Complemento"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.addressComplement}
                  helperText={errors.addressComplement?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="addressNeighborhood"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Bairro"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.addressNeighborhood}
                  helperText={errors.addressNeighborhood?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="addressState"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.addressState}
                >
                  <InputLabel>Estado</InputLabel>
                  <Select {...field} label="Estado">
                    {addressStateOptions.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.addressState && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.addressState.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="addressCity"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  margin="normal"
                  fullWidth
                  error={!!errors.addressCity}
                >
                  <InputLabel>Cidade</InputLabel>
                  <Select {...field} label="Cidade">
                    {addressCityOptions.map(opt => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.addressCity && (
              <p style={{ color: "red", marginTop: 0 }}>
                {errors.addressCity.message}
              </p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  label="E-mail"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Telefone"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              name="cellphone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Celular"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.cellphone}
                  helperText={errors.cellphone?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="text"
                  disabled
                  label="CCT ou ACT"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  value={value?.name || "Nenhum arquivo selecionado"}
                  onBlur={onBlur}
                  error={errors?.cctActFile ? true : false}
                  helperText={errors?.cctActFile?.message}
                />
              )}
              name="cctActFile"
            />
          </div>
          <div className="col-lg-5" style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              component="label"
              sx={{ margin: "4px", alignSelf: "flex-end" }}
            >
              <Upload sx={{ marginRight: "4px", fontSize: "20px" }} />
              Upload
              <input
                type="file"
                hidden
                onChange={e => {
                  const file = e.target.files[0];
                  if (file) {
                    setValue("cctActFile", file, { shouldValidate: true });
                  }
                }}
              />
            </Button>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.syndicateId ? "Adicionar" : "Salvar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
