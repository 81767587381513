import React, { useRef } from "react";
import { TextField, CircularProgress, Checkbox, ListItem } from "@mui/material";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { CheckBoxOutlineBlank } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useInfiniteScroll from "react-infinite-scroll-hook";

const DEBOUNCE_LOAD_DELAY = 500;

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxAutocomplete(props) {
  const timeout = useRef();
  const { loading } = props;
  const {
    label,
    required,
    variant,
    margin,
    fullWidth,
    error,
    helperText,
    size,
  } = props.inputprops;

  const sentryOption = { value: "sentry", label: "" };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: false,
    hasNextPage: props.infinite?.hasNextPage ?? false,
    onLoadMore: props.infinite?.fetchNextPage,
    disabled: false,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <MuiAutocomplete
      {...props}
      multiple
      size={size}
      getOptionLabel={option => option.label?.toUpperCase()}
      includeInputInList
      loadingText="Loading…"
      noOptionsText="No options"
      openText="Open"
      clearText="Clear"
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => {
        return option.value === value.value;
      }}
      onInputChange={(_, value, reason) => {
        if (reason === "input" || reason === "clear") {
          clearTimeout(timeout.current);
          timeout.current = setTimeout(() => {
            typeof props.onInputChange === "function" &&
              props.onInputChange(value);
          }, DEBOUNCE_LOAD_DELAY);
        }
      }}
      ListboxProps={{ ref: rootRef }}
      filterOptions={filterOptions => {
        const shouldRenderSentry = props.infinite?.hasNextPage;

        if (shouldRenderSentry) {
          filterOptions.push(sentryOption);
        }

        return filterOptions;
      }}
      renderOption={(optionProps, option, { selected }) => {
        if (option.value === "sentry") {
          return props.infinite?.hasNextPage || loading ? (
            <ListItem ref={sentryRef} key="sentry">
              Carregando...
            </ListItem>
          ) : null;
        }

        return (
          <li {...optionProps} key={option.value}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        );
      }}
      onChange={(_, value) => {
        props.onChange(value);
      }}
      renderInput={params => {
        return (
          <TextField
            variant={variant ?? "standard"}
            margin={margin}
            required={required}
            fullWidth={fullWidth}
            {...params}
            error={error}
            helperText={helperText}
            label={label}
            name={props.name}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
}
