import React from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "react-query";
import { getCompany } from "../../../../services/pwsApi";
import { LoadingBox } from "../../../../../components/utils";
import { makeStyles } from "@mui/styles";

import { onboardingSchema } from "./validationSchemas/onboardingSchema";
import Onboarding from "./components/Onboarding";

import { paymentSchema } from "./validationSchemas/paymentSchema";
import Payment from "./components/Payment";

import { payrollSchema } from "./validationSchemas/payrollSchema";
import Payroll from "./components/Payroll";

import { benefitsStep1Schema } from "./validationSchemas/benefitsStep1Schema";
import BenefitsStep1 from "./components/BenefitsStep1";
import BenefitsStep2 from "./components/BenefitsStep2";
import { benefitsStep2Schema } from "./validationSchemas/benefitsStep2Schema";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const STEP1 = 0;
const STEP2 = 1;
const STEP3 = 2;
const STEP4 = 3;
const STEP5 = 4;

const steps = [
  "Admissão",
  "Pagamento",
  "Folha de Pagamento",
  "Benefícios 1",
  "Benefícios 2",
];

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "primary",
    backgroundColor: "rgba(0, 0, 0, 0.25) !important",
  },
}));

export default function Registration() {
  const classes = useStyles();
  console.log("classes: " + classes);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = React.useState(0);

  const companyCode = searchParams.get("company_code");

  const companyQuery = useQuery(["company", companyCode], () =>
    getCompany(companyCode.slice(0, 3), companyCode.slice(3, 7)),
  );

  const company = companyQuery.data?.data?.data;

  const getValidationSchema = step => {
    const options = {
      STEP1: onboardingSchema,
      STEP2: paymentSchema,
      STEP3: payrollSchema,
      STEP4: benefitsStep1Schema,
      STEP5: benefitsStep2Schema,
    };
    return options[step];
  };

  const methods = useForm({
    resolver: yupResolver(getValidationSchema(activeStep)),
    defaultValues: {
      contractDuration: "",
      hasEmployeeRegistrationRule: "",
      hasWorkShiftControl: "",
      timeClockClosingPeriod: "",
      hasMonthlyAdvance: "",
      employeesAdmittedInMonth: "",
      hasMaternityLeave: "",
      advancePaymentConditions: "",
      workingDaysInMonth: "",
      processFirstInstallmentAdvance: "",
      parentSubsidiaryOrderDescription: "",
      analyticalReportByOrder: "",
      parentSubsidiaryOrderDescription: "",
      syntheticReportByOrder: "",
      payFirstInstallmentWithAverages: "",
      firstInstallmentAverage: "",
      hasAdvanceBusinessRule: "",
      firstInstallmentAdvancePercentage: "",
      internsProLaboreChristmasBonus: "",
      analyticalReportByOrder: "",
      syntheticReportByOrder: "",
      eSocialFraming: "",
      hasPayrollLoanConcession: "",
      deductionFollowsCurrentLegislation: "",
      informationReceivedFormat: "",
      deductTotalInstallmentAllowsNegativeBalance: "",
      informationReceivedFormat: "",
      informationReceivedDate: "",
      companyCitizenProgramMaternityExtension: "",
      usesBankTransferFile: "",
      isBankFileLayoutDPlusOne: "",
      hasSequentialNumberRule: "",
      hasSpecificBusinessRule: "",
      anticipatesVacationDiscounts: "",
      hasSpecificDiscountRule: "",
      usesAverages: "",
      hasVacationBenefit: "",
      bankName: "",
      bankBranch: "",
      accountNumber: "",
      agreementCode: "",
      healthPlan: "",
      multipleContracts: "",
      contractType: "",
      companyCostPercentage: "",
      employeeCostPercentage: "",
      dependentCostPercentage: "",
      aggregateCostPercentage: "",
      coparticipation: "",
      coparticipationCompanyPercentage: "",
      coparticipationEmployeePercentage: "",
      coparticipationDependentPercentage: "",
      coparticipationAggregatePercentage: "",
      nonEmployeeBeneficiaries: "",
      dentalPlan: "",
      dentalPlanType: "",
      dentalPlanCompanyCostPercentage: "",
      dentalPlanEmployeeCostPercentage: "",
      dentalPlanDependentCostPercentage: "",
      dentalPlanAggregateCostPercentage: "",
      dentalPlanNonEmployeeBeneficiaries: "",
      transportationVoucher: "",
      transportationDeductionPeriod: null,
      transportationCalculation: "",
      transportationPurchaseDeadline: null,
      absencesDeducted: "",
      medicalLeaveDeducted: "",
      accidentBenefit: "",
      otherDeductionFactors: "",
      illnessBenefit: "",
      maternityLeaveBenefit: "",
      vacationBenefit: "",
      terminationBenefitDeduction: "",
      transportationPayrollDeduction: "",
      mealVoucher: "",
      mealCalculationPeriod: null,
      mealDeductionPeriod: null,
      mealCalculationExecuted: "",
      mealPurchaseDeadline: null,
      mealBenefitType: "",
      mealValueOrFixed: "",
      proportionalCalculation: "",
      mealAbsencesDeducted: "",
      mealMedicalLeaveDeducted: "",
      mealIllnessBenefit: "",
      mealAccidentBenefit: "",
      mealMaternityLeaveBenefit: "",
      mealVacationBenefit: "",
      mealTerminationBenefitDeduction: "",
      mealPayrollDeduction: "",
      mealOtherDeductionFactors: "",
      foodVoucher: "",
      foodCalculationPeriod: null,
      foodDeductionPeriod: null,
      foodCalculationExecuted: "",
      foodPurchaseDeadline: null,
      foodBenefitType: "",
      foodValueOrFixed: "",
      foodProportionalCalculation: "",
      foodAbsencesDeducted: "",
      foodMedicalLeaveDeducted: "",
      foodIllnessBenefit: "",
      foodAccidentBenefit: "",
      foodMaternityLeaveBenefit: "",
      foodVacationBenefit: "",
      foodTerminationBenefitDeduction: "",
      foodPayrollDeduction: "",
      foodOtherDeductionFactors: "",
    },
  });

  const handleCloseDialog = () => {
    searchParams.delete("screen");
    setSearchParams(searchParams);
  };

  const handleNext = () => {
    setActiveStep(prev => Math.min(prev + 1, steps.length - 1));
  };

  const handleBack = () => {
    setActiveStep(prev => Math.max(prev - 1, 0));
  };

  const renderStepContent = step => {
    const options = {
      [STEP1]: <Onboarding />,
      [STEP2]: <Payment />,
      [STEP3]: <Payroll />,
      [STEP4]: <BenefitsStep1 />,
      [STEP5]: <BenefitsStep2 />,
    };

    return options[step] || <Typography>Etapa não encontrada</Typography>;
  };

  return (
    <>
      <FormProvider {...methods}>
        <Dialog
          fullScreen
          open
          onClose={handleCloseDialog}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative", mb: 3 }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <Close />
              </IconButton>

              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Regra de negócio
              </Typography>
            </Toolbar>
          </AppBar>

          <Backdrop className={classes.backdrop} open={companyQuery.isLoading}>
            <LoadingBox />
          </Backdrop>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ p: 3 }}>
            {renderStepContent(activeStep)}
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                Voltar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                Próxima Etapa
              </Button>
            </Box>
          </Box>
        </Dialog>
      </FormProvider>
    </>
  );
}
