import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

import { string, object, array } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient, useInfiniteQuery } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { getCompanies } from "../../../../../services/pwsApi";
import { storeFeed, updateFeed } from "../../../../../services/mobileApi";
import CheckboxAutocomplete from "../../../../../components/react-form/CheckboxAutocomplete";

export function AppFeedDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const [companyQuery, setCompanyQuery] = useState("");

  const companyCode = searchParams.get("company_code");

  const validateSchema = object().shape({
    companies: array().required(),
    title: string().required(),
    banner: string().required(),
    content: string().required(),
  });

  const companiesQuery = useInfiniteQuery(
    ["companies", companyQuery],
    ({ pageParam = 1 }) => {
      return getCompanies(companyQuery, {
        page: pageParam,
      });
    },
    {
      getNextPageParam: lastPage => {
        if (lastPage.data?.data?.length > 0) {
          return lastPage.data?.meta?.current_page + 1;
        }

        return false;
      },
      refetchOnWindowFocus: false,
    },
  );

  const companyOptions =
    companiesQuery.data?.pages
      ?.reduce((data, page) => {
        return [...data, ...page.data.data];
      }, [])
      .map(option => ({
        value: `${option.APS_COD_EMPRESA}${option.APS_COD_FILIAL}`,
        label: `${option.APS_COD_EMPRESA}${option.APS_COD_FILIAL} - ${option.APS_DESC_FILIAL}`,
      })) ?? [];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: {
      feed_id: data?.id,
      companies: data?.companies ?? [],
      title: data?.title ?? "",
      banner: data?.banner ?? "",
      content: data?.content ?? "",
    },
  });

  const storeFeedMutation = useMutation(storeFeed, {
    onSuccess: () => {
      toast.success("Notícia criada com sucesso.");
      queryClient.invalidateQueries("app-feed");
      onClose();
    },
    onError: err => {
      toast.error(err?.response?.data?.message ?? "Erro ao criar notícia.");
    },
  });

  const updateFeedMutation = useMutation(
    variables => {
      const { feed_id, ...restVariables } = variables;

      return updateFeed(feed_id, restVariables);
    },
    {
      onSuccess: () => {
        toast.success("Notícia atualizado com sucesso.");
        queryClient.invalidateQueries("app-feed");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao editar notícia.");
      },
    },
  );

  const onSubmit = data => {
    let { feed_id, title, banner, content } = data;

    const companyCodes = data.companies.map(company => company.value);
    if (!companyCodes.includes(companyCode)) {
      companyCodes.push(companyCode);
    }

    if (feed_id) {
      updateFeedMutation.mutate({
        feed_id,
        companies: companyCodes,
        title,
        banner,
        content,
      });
    } else {
      storeFeedMutation.mutate({
        companies: companyCodes,
        title,
        banner,
        content,
      });
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>{!data?.id ? `Adicionar` : `Editar`} notícia</DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-sm-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <CheckboxAutocomplete
                    onChange={onChange}
                    onInputChange={value => {
                      setCompanyQuery(value);
                    }}
                    onBlur={onBlur}
                    value={value}
                    options={companyOptions}
                    loading={companiesQuery.isLoading}
                    size="small"
                    infinite={{
                      fetchNextPage: companiesQuery.fetchNextPage,
                      hasNextPage: companiesQuery.hasNextPage,
                    }}
                    inputprops={{
                      label: "Empresa",
                      required: false,
                      fullWidth: true,
                      error: errors?.companies ? true : false,
                      helperText: errors?.companies?.message,
                      variant: "standard",
                      margin: "normal",
                    }}
                  />
                );
              }}
              name="companies"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  label="Título"
                  margin="normal"
                  fullWidth
                  name="title"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.title ? true : false}
                  helperText={errors?.title?.message}
                  onChange={onChange}
                />
              )}
              name="title"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  multiline
                  maxRows={10}
                  label="Conteúdo"
                  margin="normal"
                  fullWidth
                  name="content"
                  variant="standard"
                  value={value}
                  onBlur={onBlur}
                  error={errors?.content ? true : false}
                  helperText={errors?.content?.message}
                  onChange={onChange}
                />
              )}
              name="content"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-7">
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="text"
                  disabled
                  label="Banner"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  value={value?.name || "Nenhum arquivo selecionado"}
                  onBlur={onBlur}
                  error={errors?.file ? true : false}
                  helperText={errors?.file?.message}
                />
              )}
              name="file"
            />
          </div>
          <div className="col-lg-5" style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              component="label"
              sx={{ margin: "4px", alignSelf: "flex-end" }}
            >
              <Upload sx={{ marginRight: "4px", fontSize: "20px" }} />
              Upload
              <input
                type="file"
                hidden
                onChange={e => {
                  const file = e.target.files[0];
                  if (file) {
                    setValue("file", file, { shouldValidate: true });
                  }
                }}
              />
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.id ? `Adicionar` : `Editar`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
