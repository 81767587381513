import React, { useMemo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";

export default function SyndicatesTable({ data, rows }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page") ?? 1) - 1,
    pageSize: Number(searchParams.get("page_size") ?? 50),
  });

  const handlePaginationModelChange = newModel => {
    searchParams.set("page", newModel.page + 1);
    searchParams.set("page_size", newModel.pageSize);

    setSearchParams(searchParams);
    setPaginationModel(newModel);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Código",
        field: "syndicateCode",
        flex: 1,
        renderCell: params => params.row?.RCE_CODIGO,
      },
      {
        headerName: "Cidade",
        field: "syndicateCity",
        flex: 1,
        renderCell: params => params.row?.RCE_MUNIC,
      },
      {
        headerName: "CNPJ",
        field: "syndicateCnpj",
        flex: 1,
        renderCell: params => params.row?.RCE_CGC,
      },
      {
        headerName: "Nome",
        field: "syndicateName",
        flex: 1,
        renderCell: params => params.row?.RCE_DESCRI,
      },
      // {
      //   field: "actions",
      //   type: "actions",
      //   headerName: "Ações",
      //   width: 70,
      //   getActions: params => [
      //     <GridActionsCellItem
      //       onClick={() => {
      //         searchParams.set("syndicate_id", params.row.RCE_CODIGO);
      //         setSearchParams(searchParams);
      //       }}
      //       label="Editar"
      //       showInMenu
      //     />,
      //   ],
      // },
    ],
    [searchParams, setSearchParams],
  );

  return (
    <DataGrid
      rows={data}
      columns={columns}
      getRowId={row => row.R_E_C_N_O_}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      rowCount={rows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
}
