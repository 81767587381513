import React, { useMemo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSearchParams } from "react-router-dom";

export default function AmountsTable({ data, rows }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationModel, setPaginationModel] = useState({
    page: Number(searchParams.get("page") ?? 1) - 1,
    pageSize: Number(searchParams.get("page_size") ?? 50),
  });

  const handlePaginationModelChange = newPaginationModel => {
    searchParams.set("page", newPaginationModel.page + 1);
    searchParams.set("page_size", newPaginationModel.pageSize);

    setSearchParams(searchParams);
    setPaginationModel(newPaginationModel);
  };

  const columns = useMemo(
    () => [
      {
        headerName: "Filial",
        field: "subsidiary",
        flex: 1,
        renderCell: params => params.row?.RV_FILIAL,
      },
      {
        headerName: "Código",
        field: "code",
        flex: 1,
        renderCell: params => params.row?.RV_COD,
      },
      {
        headerName: "Descrição",
        field: "description",
        flex: 1,
        renderCell: params => params.row?.RV_DESC,
      },
      {
        headerName: "Tipo",
        field: "type",
        flex: 1,
        renderCell: params => params.row?.RV_TIPO,
      },
      {
        headerName: "Tipo código",
        field: "codeType",
        flex: 1,
        renderCell: params => params.row?.RV_TIPOCOD,
      },
      // {
      //   field: "actions",
      //   type: "actions",
      //   headerName: "Ações",
      //   width: 70,
      //   getActions: params => [
      //     <GridActionsCellItem
      //       onClick={() => {
      //         searchParams.set("amount_id", params.row.RV_COD);
      //         setSearchParams(searchParams);
      //       }}
      //       label="Editar"
      //       showInMenu
      //     />,
      //   ],
      // },
    ],
    [searchParams, setSearchParams],
  );

  return (
    <DataGrid
      rows={data}
      columns={columns}
      getRowId={row => row.RV_COD}
      sx={{ flex: 1, border: "none" }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableRowSelectionOnClick
      rowCount={rows}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
    />
  );
}
