import * as yup from "yup";

export const benefitsStep2Schema = yup.object().shape({
  // Plano de Saúde
  healthPlan: yup.string(),
  multipleContracts: yup.string(),
  contractType: yup.string(),
  companyCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  employeeCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  dependentCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  aggregateCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  coparticipation: yup.string(),
  coparticipationCompanyPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  coparticipationEmployeePercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  coparticipationDependentPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  coparticipationAggregatePercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  nonEmployeeBeneficiaries: yup.string(),

  // Plano Odontológico
  dentalPlan: yup.string(),
  dentalPlanType: yup.string(),
  dentalPlanCompanyCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  dentalPlanEmployeeCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  dentalPlanDependentCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  dentalPlanAggregateCostPercentage: yup
    .string()
    .matches(/^\d+$/, "Deve ser um número válido"),
  dentalPlanNonEmployeeBeneficiaries: yup.string(),

  // Vale Transporte
  transportationVoucher: yup.string(),
  transportationDeductionPeriod: yup.date(),
  transportationCalculation: yup.string(),
  transportationPurchaseDeadline: yup.date(),
  absencesDeducted: yup.string(),
  medicalLeaveDeducted: yup.string(),
  accidentBenefit: yup.string(),
  otherDeductionFactors: yup.string(),
  illnessBenefit: yup.string(),
  maternityLeaveBenefit: yup.string(),
  vacationBenefit: yup.string(),
  terminationBenefitDeduction: yup.string(),
  transportationPayrollDeduction: yup.string(),

  // Vale Refeição
  mealVoucher: yup.string(),
  mealCalculationPeriod: yup.date(),
  mealDeductionPeriod: yup.date(),
  mealCalculationExecuted: yup.string(),
  mealPurchaseDeadline: yup.date(),
  mealBenefitType: yup.string(),
  mealValueOrFixed: yup.string(),
  proportionalCalculation: yup.string(),
  mealAbsencesDeducted: yup.string(),
  mealMedicalLeaveDeducted: yup.string(),
  mealIllnessBenefit: yup.string(),
  mealAccidentBenefit: yup.string(),
  mealMaternityLeaveBenefit: yup.string(),
  mealVacationBenefit: yup.string(),
  mealTerminationBenefitDeduction: yup.string(),
  mealPayrollDeduction: yup.string(),
  mealOtherDeductionFactors: yup.string(),

  // Vale Alimentação (Adicionado)
  foodVoucher: yup.string(),
  foodCalculationPeriod: yup.date(),
  foodDeductionPeriod: yup.date(),
  foodCalculationExecuted: yup.string(),
  foodPurchaseDeadline: yup.date(),
  foodBenefitType: yup.string(),
  foodValueOrFixed: yup.string(),
  foodProportionalCalculation: yup.string(),
  foodAbsencesDeducted: yup.string(),
  foodMedicalLeaveDeducted: yup.string(),
  foodIllnessBenefit: yup.string(),
  foodAccidentBenefit: yup.string(),
  foodMaternityLeaveBenefit: yup.string(),
  foodVacationBenefit: yup.string(),
  foodTerminationBenefitDeduction: yup.string(),
  foodPayrollDeduction: yup.string(),
  foodOtherDeductionFactors: yup.string(),
});
