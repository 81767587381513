import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

import {
  storeDepartment,
  updateDepartment,
} from "../../../../../services/pwsApi";

const departmentSchema = object().shape({
  departmentCode: string().required("Informe o código do departamento."),
  departmentName: string().required("Informe o nome do departamento."),
});

export function DepartmentsDialog({ onClose, data }) {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const companyCode = searchParams.get("company_code");
  const company = companyCode?.slice(0, 3);
  const subsidiary = companyCode?.slice(3, 7);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(departmentSchema),
    defaultValues: {
      departmentId: data?.departmentId ?? null,
      departmentCode: data?.departmentCode ?? "",
      departmentName: data?.departmentName ?? "",
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        departmentId: data.departmentId ?? null,
        departmentCode: data.departmentCode ?? "",
        departmentName: data.departmentName ?? "",
      });
    }
  }, [data, reset]);

  const storeMutation = useMutation(storeDepartment, {
    onSuccess: () => {
      toast.success("Departamento criado com sucesso.");
      queryClient.invalidateQueries("departments");
      onClose();
    },
    onError: () => {
      toast.error("Erro ao criar departamento.");
    },
  });

  const updateMutation = useMutation(
    variables => updateDepartment(variables.departmentId, variables),
    {
      onSuccess: () => {
        toast.success("Departamento atualizado com sucesso.");
        queryClient.invalidateQueries("departments");
        onClose();
      },
      onError: () => {
        toast.error("Erro ao atualizar departamento.");
      },
    },
  );

  const onSubmit = formData => {
    const { departmentId, departmentCode, departmentName } = formData;

    const payload = {
      companyCode: company,
      subsidiaryCode: subsidiary,
      departmentCode,
      departmentName,
    };

    if (departmentId) {
      updateMutation.mutate({ departmentId, ...payload });
    } else {
      storeMutation.mutate(payload);
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open onClose={onClose}>
      <DialogTitle>
        {!data?.departmentId ? "Adicionar Departamento" : "Editar Departamento"}
      </DialogTitle>

      <DialogContent dividers>
        <div className="row">
          <div className="col-md-12">
            <Controller
              name="departmentCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Código do Departamento"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.departmentCode}
                  helperText={errors.departmentCode?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Controller
              name="departmentName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome do Departamento"
                  margin="normal"
                  fullWidth
                  variant="standard"
                  error={!!errors.departmentName}
                  helperText={errors.departmentName?.message}
                />
              )}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          {!data?.departmentId ? "Adicionar" : "Editar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
