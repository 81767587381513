import React from "react";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Paper,
  TextField,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export default function BenefitsStep2() {
  const { control } = useFormContext();
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="hasBasicBasketBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="has-basic-basket-benefits-2-label">
                    Possui cesta básica?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="has-basic-basket-benefits-2-label"
                    id="has-basic-basket-benefits-2-label"
                    variant="outlined"
                    label="Possui cesta básica?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="businessRuleDescriptionBasicBasketBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descreva a regra de negócio"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductUnusedBasicBasketOnResignationBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deduct-unused-basic-basket-on-resignation-benefits-2-label">
                    Sobre a rescisão, desconta o benefício não utilizado?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deduct-unused-basic-basket-on-resignation-benefits-2-label"
                    id="deduct-unused-basic-basket-on-resignation-benefits-2"
                    variant="outlined"
                    label="Sobre a rescisão, desconta o benefício não utilizado?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="payrollDeductionBasicBasketBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="payroll-deduction-basic-basket-benefits-2-label">
                    Desconto em folha de pagamento
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="payroll-deduction-basic-basket-benefits-2-label"
                    id="payroll-deduction-basic-basket-benefits-2"
                    variant="outlined"
                    label="Desconto em folha de pagamento"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Refeitório
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="usesCafeteriaBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="uses-cafeteria-benefits-2-label">
                    Utiliza refeitório?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="uses-cafeteria-benefits-2-label"
                    id="uses-cafeteria-benefits-2"
                    variant="outlined"
                    label="Utiliza refeitório?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="businessRuleDescriptionCafeteriaBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Descreva a regra de negócio"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="deductUnusedCafeteriaOnResignationBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="deduct-unused-cafeteria-on-resignation-benefits-2-label">
                    Sobre a rescisão, desconta o benefício não utilizado?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="deduct-unused-cafeteria-on-resignation-benefits-2-label"
                    id="deduct-unused-cafeteria-on-resignation-benefits-2"
                    variant="outlined"
                    label="Sobre a rescisão, desconta o benefício não utilizado?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="payrollDeductionCafeteriaBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="payroll-deduction-cafeteria-benefits-2-label">
                    Desconto em folha de pagamento
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="payroll-deduction-cafeteria-benefits-2-label"
                    id="payroll-deduction-cafeteria-benefits-2-label"
                    variant="outlined"
                    label="Desconto em folha de pagamento"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="specificBusinessRulePayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Existe regra específica? Se existir, descreva a regra de negócio"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid> */}
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Convênios
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="informationFormatAgreementsBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="information-format-agreements-benefits-2-label">
                    Em que formato recebe a informação?
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="information-format-agreements-benefits-2-label"
                    id="information-format-agreements-benefits-2"
                    variant="outlined"
                    label="Em que formato recebe a informação?"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Período de apuração"
                  fullWidth
                  name="apportionPeriodAgreementsBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="apportionPeriodAgreementsBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="pharmacyAgreementBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Farmácia?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Qual data recebem as informações?"
                  fullWidth
                  name="informationReceivedDateAgreementsBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="informationReceivedDateAgreementsBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {/* <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="informationFormatAgreementsBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Em que formato recebe a informação?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="payrollDeductionAgreementsBenefits2"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="payroll-deduction-agreements-benefits-2-label">
                    Desconto em folha de pagamento
                  </InputLabel>
                  <Select
                    {...field}
                    labelId="payroll-deduction-agreements-benefits-2-label"
                    id="payroll-deduction-agreements-benefits-2"
                    variant="outlined"
                    label="Desconto em folha de pagamento"
                  >
                    <MenuItem value="" disabled>
                      Selecione
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="hasOtherAgreementsBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Possui outros convênios/benefícios?"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Calendário de pagamento/recebimento
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento do Vale Alimentação"
                  fullWidth
                  name="foodVoucherPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="foodVoucherPaymentDateBenefits2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento do PLR"
                  fullWidth
                  name="plrPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="plrPaymentDateBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento do Vale Refeição"
                  fullWidth
                  name="mealVoucherPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="mealVoucherPaymentDateBenefits2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento da Cesta Básica"
                  fullWidth
                  name="basicBasketPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="basicBasketPaymentDateBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento da 1° parcela do 13°"
                  fullWidth
                  name="firstInstallmentThirteenthPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="firstInstallmentThirteenthPaymentDateBenefits2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento do Adiantamento"
                  fullWidth
                  name="advancePaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="advancePaymentDateBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento do Vale Transporte"
                  fullWidth
                  name="transportVoucherPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="transportVoucherPaymentDateBenefits2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de pagamento da 2° parcela do 13°"
                  fullWidth
                  name="secondInstallmentThirteenthPaymentDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="secondInstallmentThirteenthPaymentDateBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Folha de pagamento"
                  fullWidth
                  name="payrollBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="payrollBenefits2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de recebimento da comissão (fechamento)"
                  fullWidth
                  name="commissionReceiptDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="commissionReceiptDateBenefits2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de recebimento do plano odontológico"
                  fullWidth
                  name="dentalPlanReceiptDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="dentalPlanReceiptDateBenefits2"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                  required
                  type="date"
                  label="Data de recebimento do plano de saúde"
                  fullWidth
                  name="healthPlanReceiptDateBenefits2"
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
              name="healthPlanReceiptDateBenefits2"
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: 2,
          mx: 4,
          mb: 3,
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#7A7A7A",
            fontWeight: "600",
            my: 1,
          }}
        >
          Previdência Social
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="passwordBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Senha"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="usernameBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Usuário"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="eSocialBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="eSocial"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="proxyExpirationDateBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Data vencimento procuração"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="federalRevenueResponsibleDobBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Responsável na Receita Federal - data de nascimento"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="emailBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="nameBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Nome"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Controller
              name="cpfBenefits2"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="CPF"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
